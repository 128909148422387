import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';
const snippet = require('raw-loader!../../../../data/snippets/accessibility-text-resize.example');

const IndexPage = ({}) => {
  return (
    <PageWithSubNav pageType="code" title="Text" designCode subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Accessibility"
        tierThree="Text"
      />

      <Section title="Resizing Text">
        <SectionSubhead>Low-vision Users</SectionSubhead>
        <Paragraph>
          <Link href="https://www.who.int/news-room/fact-sheets/detail/blindness-and-visual-impairment">
            About 29% of the world's population
          </Link>{' '}
          is affected by some form of low vision impairment that may not be
          correctable. This includes degraded eyesight in one or both eyes in
          the form of tunnel vision, blurred vision, blind spots, or eye
          injuries. It's our responsibility to think about a variety of ways we
          may be able to maximize the usability and readibility of our content,
          including how text{' '}
          <Link href="https://www.w3.org/WAI/WCAG21/Understanding/resize-text.html">
            can be resized to up to 200%
          </Link>{' '}
          (except for captions and images of text) without loss of meaning or
          functionality.
        </Paragraph>
        <Paragraph>
          As a developer, that typically means using certain CSS styling
          conventions to avoid issues when the user resizes text using custom
          stylesheets or browser settings.
        </Paragraph>
        <SectionSubhead>Use relative size units</SectionSubhead>
        <Paragraph>
          Rather than using <code>px</code> to declare font sizes, line heights,
          or even margin and padding, use a relative unit such as{' '}
          <code>em</code> or <code>rem</code>, which responds to browser setting
          changes to font size. Remember, the default browser size for{' '}
          <code>1rem</code> is <code>16px</code>. Either <code>em</code> or{' '}
          <code>rem</code> should work, as long as there are no static font
          sizes declared.{' '}
          <Link href="https://zellwk.com/blog/rem-vs-em/">
            Learn more about&nbsp;
            <code>em</code>
            &nbsp;vs&nbsp;
            <code>rem</code>
          </Link>
          .
        </Paragraph>
        <CodeSnippet platform="css" disableCodeEditing code={snippet} />
        <SectionSubhead>Make containers flexible and responsive</SectionSubhead>
        <Paragraph>
          Users increasing text size or reducing the browser window size to more
          easily read and consume content from a page will expect that our
          interfaces respond to various size differences without hiding content
          either off-screen (not scrollable) or truncated.
        </Paragraph>
        <Paragraph>
          There isn't a particular style or method to use that will always
          prevent this. There are a few things to keep in mind, though:
        </Paragraph>
        <List type="unordered">
          <li>
            Use modern, responsive patterns for defining layouts and containers
            (Flexbox, CSS Grid).
          </li>
          <li>
            Make testing interfaces at different browser sizes and increased
            font sizes part of standard QA.
          </li>
          <li>
            Avoid setting static heights and widths for elements; use flex
            styling, min-heights/min-widths, or other flexible sizing options.
          </li>
        </List>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
